.contact {
  --form-height: 530px;
  position: absolute;
  display: flex;
  justify-content: center;
  min-height: auto;
  width: 100%;

  @media (max-width: 696px) {
    & {
      padding-top: 0;
    }
  }
}

.contact--entered,
.contact--exiting {
  position: relative;
}

.contact_form {
  max-width: var(--maxWidthS);
  width: 100%;
  padding: var(--space2XL) 0;

  @media (max-width: 696px) {
    & {
      padding-top: --spaceL;
      padding: var(--space5XL) 0 var(--space2XL);
      align-self: flex-start;
    }
  }
}

.contact_title {
  margin-bottom: var(--space2XL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--delay);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space5XL), 0);
    }
  }
}

.contact_title--entering,
.contact_title--entered {
  transform: none;
  opacity: 1;
}

.contact_title--exiting {
  transition-duration: var(--durationM);
  transition-delay: 0s;
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, calc(var(--space2XL) * -1), 0);
    }
  }
}

.contact_title--hidden {
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, var(--space5XL), 0);
    }
  }
}

.contact_divider {
  margin-bottom: var(--space3XL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--delay);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space5XL), 0);
    }
  }
}

.contact_divider--entering,
.contact_divider--entered {
  transform: none;
  opacity: 1;
}

.contact_divider--hidden {
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, var(--space5XL), 0);
    }
  }
}

.contact_divider--exiting {
  transition-duration: var(--durationM);
  transition-delay: 0s;
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, calc(var(--space2XL) * -1), 0);
    }
  }
}

.articles_divider {
  height: 3px;
  margin: 0;
  border: 0;
  background-color: rgb(var(--rgbText) / 0.1);
}

.contact_button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-delay: var(--delay);
  transition-duration: var(--durationXL);
  opacity: 0;
  justify-self: flex-start;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space3XL), 0);
    }
  }
}

.contact_button--entered {
  transition-delay: 0ms;
  transition-duration: var(--durationM);
}

.contact_button--hidden {
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, var(--space3XL), 0);
    }
  }
}

.contact_button--entering,
.contact_button--entered {
  transform: none;
  opacity: 1;
}

.contact_button--entering:hover {
  transform: none;
}

.contact_button--exiting {
  transition-duration: var(--durationM);
  transition-delay: 0s;
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transform: translate3d(0, calc(var(--space2XL) * -1), 0);
    }
  }
}

.contact_complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--spaceXL) var(--spaceM);
  position: fixed;
  text-align: center;
  inset: 0;
}

.contact_complete-title {
  margin: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--delay);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space3XL), 0);
    }
  }
}

.contact_complete-title--entered {
  transform: none;
  opacity: 1;
}

.contact_complete-text {
  margin-top: var(--spaceM);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--delay);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space3XL), 0);
    }
  }
}

.contact_complete-text--entered {
  transform: none;
  opacity: 1;
}

.contact_complete-button {
  margin-top: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--delay);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--space3XL), 0);
    }
  }
}

.contact_complete-button--entered {
  transform: none;
  opacity: 1;
}

.icon-container{
  display: flex;
  width: 100%;
}

.navbar_nav-icons-contact {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 16;

  @media (max-width: 696px), (max-height: 696px) {
    & {
      flex-direction: row;
      /* position: absolute; */
      /* bottom: var(--spaceXL); */
      left: var(--spaceXL);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      left: var(--spaceL);
      transform: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.navbar_nav-icon-link-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 0px;
  width: auto;
  height: var(--space3XL);
  font-weight: var(--fontWeightMedium);
  font-size: var(--navbarNavFontSize);
  color: var(--colorTextLight);
  transition: color var(--durationM) var(--bezierFastoutSlowin);

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: rgb(var(--rgbAccent));
  }
}

.navbar_nav-icon-contact {
  fill: currentColor;
  transition: fill var(--durationM) ease;
}

.img-div-contact{
  padding: 8px 8px 8px 0px;
}
