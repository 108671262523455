.countdown {
  margin-top: 100px;
  float: left;
  width: 25%;
  padding: 0 10px;
  font-weight: var(--fontWeightBold);

  @media (max-width: 696px) {
    & {
      margin-top: 20px;
    }
  }
}

.countdown-main{
  width: 100vw;
  display: flex;
  justify-content: center;

  @media (max-width: 696px) {
    & {
      display: block;
    }
  }
}

.countdown-heading {
  padding: 10px;
}

.counter {
  font-size: calc(0.9em + 1vmin);
}

.container {
  margin: 0 -5px;
  width: 100vw;
  padding-right: calc(var(--space5XL) * 2);
  padding-left: calc(var(--space5XL) * 2 + 50px);
  padding-bottom: 5em;

  @media (max-width: 1024px) {
    & {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 696px) {
    & {
      margin-top: 0;
      padding-top: 90px;
      padding-left: 25px;
      padding-right: 25px;
      overflow-x: hidden;
      padding-bottom: 0px;
    }
  }
}

.container:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .container{
    margin: 0px 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .countdown_column {
    height: auto !important;
  }

  .countdown {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.countdown_column {
  padding: 16px;
  padding-top: 25px;
  text-align: center;
  min-height: 12vw;
  background-color: rgb(var(--cardbackground));
  box-shadow: var(--cardshadow);
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 0.1s;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: opacity, transform;
      transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
    }
  }
}
@media screen and (max-width: 1367px) {
  .countdown_column {
    height: 14vw;
  }

  .container {
    padding-right: calc(var(--space5XL) + 25px);
    padding-left: calc(var(--space4XL) * 2 + 25px);
  }
}

.countdown_column--entered {
  transform: none;
  opacity: 1;
}
