.container1 {
  margin: 0 -5px;
  width: 100vw;
  display: flex;
  justify-content: center;
  padding-right: var(--space5XL);
  padding-left: calc(var(--space5XL) * 2 + 50px);
  padding-bottom: 5em;

  @media (max-width: 1024px) {
    & {
      padding-top: 50px;
      padding-left: 160px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1367px) {

  .container1 {
    padding-right: calc(var(--space2XL));
    padding-left: calc(var(--space4XL) * 2 + 25px);
  }
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 21));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 21));
  }
}
.slider {
  background: rgb(var(--rgbBackground));
  box-shadow: var(--cardshadow);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  margin-right: var(--space4XL);
}
.slider::before,
.slider::after {
  content: '';
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 60s linear infinite;
  animation: scroll 60s linear infinite;
  display: flex;
  width: calc(250px * 21);
}

.slider:hover .slide-track {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.slider .slide {
  height: 100px;
  width: 250px;
}

@media screen and (max-width: 600px) {
  .container1 {
    margin: 0px 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding: 16px;
  }

  .slider {
    margin-right: 0px;
  }

  .slider .slide {
    height: 100px;
    width: 250px;
  }
}
