.tech-svg {
  fill: #fff;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.backend-svg {
  margin-left: 15px;
}

.technology-wrapper {
  display: grid;
  grid-template-columns: 1fr 35%;
  grid-column-gap: var(--space5XL);
  max-width: var(--maxWidthL);
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    & {
      max-width: 600px;
      grid-template-columns: 100%;
    }
  }
}

.Technology-container {
  border-radius: 10px;
  box-shadow: var(--cardshadow);
  display: flex;
  flex-direction: column;
  height: 450px;
  letter-spacing: 2px;
  justify-content: space-between;
  overflow: hidden;
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: opacity, transform;
      transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
    }
  }

  @media (max-width: 1024px) {
    & {
      width: 100%;
      margin: 0px 0px;
      padding-right: 0px;
      padding-left: 0px;
      overflow-y: hidden !important;
    }
  }
}

.Technology-container--entered {
  transform: none;
  opacity: 1;
}

.Technology-container > div + div {
  box-shadow: inset 0px 5px 5px rgba(0, 0, 0, 0.2);
}

#location {
  align-items: baseline;
  background-image: url('./assets/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 400px;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: height 0.4s ease-in-out;
}

#location.open-menu {
  height: 200px;
  transition: height 0.4s ease-in-out;
}
/* //////////////////////////////////////// general / icon //////*/
.Technology-section {
  height: 100px;
  overflow: hidden;
  position: relative;
  transition: height 0.5s;
  transition-timing-function: cubic-bezier(0.68, 0.03, 0.32, 1.47);
  width: 100%;
}

.Technology-section:hover {
  height: 450px;
}

.Technology-section-backend:hover {
  height: 650px !important;
}

.Technology-section-designing:hover {
  height: 300px;
}

.Technology-section-other:hover {
  height: 400px;
}

.time {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8em;
  margin: 0;
  position: absolute;
  right: 1em;
  top: 1em;
}

.Technology-section:hover .time {
  color: rgba(255, 255, 255, 1);
  font-size: 1.2em;
}

.icon-box {
  animation-origin: center;
  height: 50px;
  left: -10em;
  opacity: 0;
  position: absolute;
  top: 3.5em;
  transition-timing-function: cubic-bezier(0.68, 0.03, 0.32, 1.47);
  width: 80px;
}

.Technology-section:hover .icon-box {
  animation: icon-anima 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes icon-anima {
  0% {
    left: -10em;
    opacity: 0;
  }
  100% {
    left: 2em;
    opacity: 1;
  }
}

.Technology-text-wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 1em;
  top: 4em;
  @media (max-width: 1024px) {
    & {
      font-size: 0.9rem;
      line-height: 1.2 !important;
    }
  }
}

.Technology-text-wrapper p {
  margin: 0;
  margin-left: auto;
}

.Technology-section:hover .Technology-text-wrapper {
  animation: text-anima 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes text-anima {
  0% {
    top: 10em;
    opacity: 0;
  }
  100% {
    top: 4.2em;
    opacity: 1;
  }
}

#frontend {
  background-color: var(--firstCardColour);
}

#afternoon {
  background-color: var(--secondCardColour);
}

#evening {
  background-color: var(--thirdCardColour);
}

#nightday {
  background-color: var(--fourthCardColour);
}
