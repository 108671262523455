.profile {
  width: 100vw;
  min-height: auto;
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    & {
      padding-top: 50px;
      padding-right: 80px;
      padding-left: 160px;
      height: auto;
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 696px) {
    & {
      margin-top: 0;
      padding-left: 25px;
      padding-right: 25px;
      overflow-x: hidden;
      margin-bottom: 0px;
    }
  }

  @media (max-width: 696px), (max-height: 696px) {
    & {
      padding-right: var(--spaceOuter);
      padding-left: var(--spaceOuter);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      padding-right: var(--space4XL);
      padding-left: var(--space4XL);
    }
  }
}

.profile_content {

  grid-template-columns: 1fr 50%;
  grid-column-gap: var(--space2XL);
  max-width: var(--maxWidthL);
  width: 100%;

  @media (max-width: 1024px) {
    & {
      max-width: 600px;
      grid-template-columns: 100%;
    }
  }
}

.profile_column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  transform: translate3d(0, 0, 0);
}

.profile_title {
  white-space: nowrap ; 
  margin-bottom: var(--spaceL);
  opacity: 0;
  font-size: 25px; 
  outline: none;
  transition: opacity var(--durationXL) ease var(--durationM);
}


.typed-cursor {
  opacity: 1;
  animation: blink .7s infinite;
}

@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.profile_title--entered {
  opacity: 1;
  font-family: 'Gotham';
}

.profile_description {
  margin-bottom: var(--spaceXL);
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationL);
}

.profile_description--entered {
  opacity: 1;
}

.profile_tag {
  margin-top: 100px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: var(--space4XL) 1fr;
  gap: 12px;
  align-items: center;

  @media (max-width: 1024px) {
    & {
      margin-top: 30px;
    }
  }
}

.profile_tag-text {
  font-size: var(--fontSizeH4);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: opacity, transform;
      transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
    }
  }
}

.profile_tag-text--entered {
  transform: none;
  opacity: 1;
}

