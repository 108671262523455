.project-summary {
  height: auto;
  width: 100%;
  max-height: 1080px;
  padding-bottom: var(--spaceL);
  padding-top: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  @media (max-width: 2080px) {
    & {
      margin: 40px 0;
    }
  }

  @media (max-width: 1024px) {
    & {
      height: auto;
    }
  }

  @media (max-width: 696px) {
    & {
      padding-top: 20px;
      margin-bottom: 0;
    }
  }
}

.project-summary--first {
  margin-top: 0;

  @media (max-width: 1024px) {
    & {
      margin-top: 0;
    }
  }
}

.project-summary_content {
  width: 100%;
  max-width: var(--maxWidthL);
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-column-gap: var(--space2XL);

  @media (max-width: 1680px) {
    .project-summary_content {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 1024px) {
    @nest .project-summary--alternate & {
      grid-template-columns: 100%;
    }
  }

  @media (max-width: 1024px) {
    & {
      grid-template-columns: 100%;
      flex-direction: column-reverse;
      height: auto;
    }
  }
}

.project-summary_details {
  z-index: 1;
  position: relative;

  @media (max-width: 1024px) {
    & {
      flex: 0 0 auto;
      max-width: 410px;
      grid-row: 2;
      grid-column: 1;
      justify-self: center;
    }
  }
}

.project-summary_preview {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  width: 75%;

  @media (max-width: 1024px) {
    & {
      padding-bottom: 50px;
    }
  }
}

.project-summary_index {
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: var(--spaceM);
  align-items: center;
  margin-bottom: var(--spaceXL);
}

.project-summary_index-number {
  font-size: var(--fontSizeH4);
  font-weight: var(--fontWeightMedium);
  color: rgb(var(--rgbPrimary));
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationM);
  transition-delay: 1.3s;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translateX(calc(var(--spaceM) * -1));
    }
  }
}

.project-summary_index-number--entered {
  transform: translateX(0);
  opacity: 1;
}

.project-summary_title {
  font-size: 20px !important;
  margin-bottom: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.project-summary_title--entered {
  transform: none;
  opacity: 1;
}

.project-summary_description {
  margin-bottom: var(--spaceXL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationL);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.project-summary_description--entered {
  transform: none;
  opacity: 1;
}

.project-summary_button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationXL);
  transform: translate3d(0, var(--spaceL), 0);
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.project-summary_button--entered {
  transform: none;
  opacity: 1;
}
